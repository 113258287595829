<template>
  <AssetDimensionsStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('assetDimension', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      :createRouteName="createRoute"
      :selectedRow="selectedRow"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view />
  </AssetDimensionsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { getRolesFromToken } from '@common/utils'
import { Edit2Icon, SearchIcon, TrashIcon } from 'vue-feather-icons'

import ASSET_DIMENSIONS_CONNECTION_QUERY from '#/graphql/assetDimensions/assetDimensionsConnection.gql'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'

const AssetDimensionsStyled = styled('div')`
  margin: 1rem;
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-evenly;

    color: ${p => p.theme.colors.primary};

    > div,
    button {
      padding: 0;
      cursor: pointer;
      > svg {
        color: ${p => p.theme.colors.primary};
      }
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  components: {
    AssetDimensionsStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      count: 0,
      totalCount: 0,
      models: [],
      errors: [],
      searchQuery: '',
      perPage: 10,
      page: 0,
      where: null,
      loading: true,
      selectedRow: null,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    hasNextPage() {
      return this.page < this.totalPages - 1
    },
    hasPreviousPage() {
      return this.page > 0
    },
    skip() {
      return this.page * this.perPage
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'assetDimension'
      } else {
        return null
      }
    },
    createRoute() {
      return null
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'a',
          title: this.$tc('name', 2),
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        { field: 'compatibleWidgets', key: 'compatibleWidgets', title: 'Compatible Widgets', align: 'center' },
        { field: 'unitSI', key: 'unitSI', title: 'Unit DB (SI)', align: 'center' },
        { field: 'unitMetric', key: 'unitMetric', title: 'Unit UI Metric', align: 'center' },
        { field: 'unitImperial', key: 'unitImperial', title: 'Unit UI Imperial', align: 'center' },
        { field: 'heatmap', key: 'heatmap', title: 'Heatmap', align: 'center' },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <button
                  on-click={() => this.$router.push({ name: this.editRoute, params: { id: row?.rowKey } })}
                  disabled={!this.hasWritePermission}
                >
                  <Edit2Icon size="1.3x" />
                </button>
                <div on-click={() => this.delete(row)}>
                  <TrashIcon size="1.3x" />
                </div>
              </div>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(assetDimension => {
        return {
          name: assetDimension.name,
          compatibleWidgets: assetDimension.compatibleWidgets.length,
          unitSI: assetDimension.physicalUnitSI,
          unitMetric: assetDimension.physicalUnitUIMetric,
          unitImperial: assetDimension.physicalUnitUIImperial,
          heatmap: assetDimension.hasHeatmap ? 'Yes' : 'No',
          rowKey: assetDimension.id,
          id: assetDimension.id,
          type: assetDimension.__typename,
        }
      })
      return tableData
    },
  },
  methods: {
    reload() {
      this.searchQuery = ''
      this.$apollo.queries.assetDimensionsConnection.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchQuery = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    delete(row) {
      this.selectedRow = row
    },
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            name_contains: s,
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    assetDimensionsConnection: {
      query: ASSET_DIMENSIONS_CONNECTION_QUERY,
      variables() {
        return {
          first: this.perPage,
          skip: this.skip,
          where: this.where,
        }
      },
      result({ data, loading }) {
        this.loading = loading
        this.count = get(data, 'assetDimensionsConnection.aggregate.count', 0)
        this.totalCount = get(data, 'assetDimensionsConnectionTotal.aggregate.count', 0)
        this.models = get(data, 'assetDimensionsConnection.edges', []).map(edge => edge.node)
      },
    },
  },
}
</script>
